import { Component, OnInit } from '@angular/core';
import { VersionInfoService } from './version-info.service';
import { NOT_AVAILABLE, PRODUCT_VERSION_APP_NAME } from '@app/shared/shared.consts';
import { AppSetDto } from './version-info.model';

@Component({
  selector: 'sai-version-info',
  templateUrl: './version-info.component.html',
})
export class VersionInfoComponent implements OnInit {
  appSetDto: AppSetDto;
  productVersion: string;
  isDetailsVisible = false;
  loading = true;

  constructor(private versionInfoService: VersionInfoService) {}

  ngOnInit() {
    this.versionInfoService.getAppSet().subscribe((response) => {
      this.appSetDto = response;
      this.productVersion =
        this.appSetDto?.pods?.find((x) => x?.app === PRODUCT_VERSION_APP_NAME)?.tag ??
        NOT_AVAILABLE;
      setTimeout(() => {
        this.loading = false;
      }, 10);
    });
  }

  toggleDetails() {
    this.isDetailsVisible = !this.isDetailsVisible;
  }
}
