import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { identityEntityPropContributors } from './shared/entity-contributors/identity-user-entity-prop-contributor';
import { tenantEntityPropContributors } from './shared/entity-contributors/tenant-entity-prop-contributor';
import { tenantEditFormPropContributors } from './shared/form-contributors/tenant-form-prop-contributor';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('@app/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'projects',
    loadChildren: () => import('@app/projects/project.module').then((m) => m.ProjectModule),
  },
  {
    path: 'published-model',
    loadChildren: () =>
      import('@app/published-models/published-model.module').then((m) => m.PublishedModelModule),
  },
  {
    path: 'public-endpoint',
    loadChildren: () =>
      import('@app/public-endpoints/public-endpoint.module').then((m) => m.PublicEndpointModule),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('@volo/abp.ng.account/public').then((m) => m.AccountPublicModule.forLazy()),
  },
  // {
  //   path: 'gdpr',
  //   loadChildren: () => import('@volo/abp.ng.gdpr').then((m) => m.GdprModule.forLazy()),
  // },
  {
    path: 'identity',
    loadChildren: () =>
      import('@volo/abp.ng.identity').then((m) =>
        m.IdentityModule.forLazy({
          entityPropContributors: identityEntityPropContributors,
        })
      ),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then((m) => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    loadChildren: () =>
      import('@volo/abp.ng.saas').then((m) =>
        m.SaasModule.forLazy({
          editFormPropContributors: tenantEditFormPropContributors,
          entityPropContributors: tenantEntityPropContributors,
        })
      ),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('@volo/abp.ng.audit-logging').then((m) => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'identity-server',
    loadChildren: () =>
      import('@volo/abp.ng.identity-server').then((m) => m.IdentityServerModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then((m) =>
        m.TextTemplateManagementModule.forLazy()
      ),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then((m) => m.SettingManagementModule.forLazy()),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
